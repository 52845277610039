.outstandingcarrousel-item{

.carrousel-row-bg{
    background-color: #F8F8F8 !important;
}

.carrousels > div.carrousel-item:nth-of-type(even) {
    background-color: #F8F8F8;
}

.carrousel-row{
    padding: 0px;
    position:relative;
    top:0px;
    left:0px;

     .carousel-button-group{
        position: absolute;
        bottom: 86px;
        left: 30px;
        width: 90%;
        
        svg{
            position: absolute; 
            top:0px;
            background-color: transparent;
            width: 12px;
            height: 45px;
            background-repeat: no-repeat;
            background-size: contain;
            border: 0px;
            outline:none;
            filter: drop-shadow( 3px 3px 3px rgba(0, 0, 0, .3));
            &.leftb{
                left: 0px; 
            }
            &.rightb{ 
                right: 0px; 
            }
            
            &.disable{
                opacity: 0.25;
                pointer-events: none;
            }
            &:hover{
                filter: invert(0.4) sepia(1) saturate(20) hue-rotate(162deg) brightness(0.88);
                cursor: pointer;
            }
        }

    }
}

.carrousel-videos{
    //margin-bottom: 2em;
    //margin-top: 2em;
    position: relative;
    margin: 0px;

    article{
        position: relative;
        width: 100%;
        margin: 0;
        height: auto;
        img {
            width: 100%;
           
        }

        figcaption{
            padding: 0px 10%;
            text-align: center;
            margin-bottom: 16px;
            h4{
                font-size: 20px;
                margin-bottom: 8px;
            }
            .time{
                font-size: 10px;
                font-weight: 500;

            }
        }
        
    }
}

}

