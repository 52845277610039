#myAccount{

  //position: relative;

  .fog{
    position: fixed;
    top :0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 20;
  }

  #message,
  #deletedMessage,
  #deleteConfirm{
    position: absolute;
    top: calc(50% - 140px);
    left: calc(50% - 200px);
    width: 400px;
    height: auto;
    box-shadow: 0px 3px 50px #0000004D;
    opacity: 0.95;
    background-color: white;
    padding: 2em;
    text-align: center;
    border-radius: 10px;
    z-index: 30;
    h3 {
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      color: #292929;
      font-weight: 800;
      text-align: center;
      margin: 28px auto;
    }
    button{
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      text-align: center;
      width: 121px;
      height: 40px;
      border-radius: 10px;
      font-weight: bold;
      margin: 1em;
      letter-spacing: 0px;
      outline:none;

      &.cancel{
        border: 2px solid #3EC4FD;
        background-color: #3EC4FD;
        color: #F0F0F0;
        width: 162px;
      }

      &.confirm{
        border: 2px solid #E51F00;  
        color: #E51F00;
        background-color: white;
        width: 112px;
      }
    }

  }
  margin-top: 120px;
  h2{
    font-family: 'Montserrat', sans-serif;
    font-size: 26px;
    color: #292929;
    font-weight: 800;
  }

  .myAccount-form{
    background: transparent url(/background-gray.svg) repeat-x top left;
    margin-top: 3em;
    padding-top: 3em;
  }

  .back{
    color: #292929;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 22px;
    vertical-align: middle;
    cursor: pointer;
    .icon{
      content:url(/arrow-left.svg);
  
  
      display:inline-block;
      width: 15px;
      height: 15px;
      vertical-align: middle;
    }
    &:hover .icon{
      filter: invert(73%) sepia(79%) saturate(5278%) hue-rotate(167deg) brightness(102%) contrast(105%);
    }
  }

  .avatarField{
                
    width: 232px;
    height: 232px;
    overflow: hidden;
    cursor:pointer;
    margin-top: 42px;
    position: relative;
    top: 0px;
    left: 0px;
    background-position: center center;
    background-size: cover;
    border-radius: 360px;

    &:before{
      content:"";
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #3EC4FD;
      z-index: 20;
      opacity: 0.6;

    }

    &:hover{
       &:before{
        display: block;
       }
    }
    &:after{
      content:"";
      display: block;
      position: absolute;
      width: 50px;
      height: 44px;
      bottom:25px;
      left: calc(50% - 25px);
      background-image: url("/camera.png");
      z-index: 30;
      
      -webkit-filter: drop-shadow(2px 2px 2px #00000020);
      filter:         drop-shadow(2px 2px 2px #00000020); 
      
    }

    
} 
 .MuiFormControl-root input, .MuiFormControl-root textarea{
    padding: 15px 15px 15px 0px;
  }

  .MuiFormControl-root input:disabled {
    background: url(/padlock.png) no-repeat right center !important;

  }

  .MuiAutocomplete-clearIndicator {
    visibility: visible !important;
  }

  label{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: bolder;
    color: #5E5E5E;
    letter-spacing: -0.12px;
    &.MuiInputLabel-shrink {
        transform: translate(0, 1.5px) scale(1);
    }
  }


  .btn{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    text-align: center;
    width: 171px;
    height: 44px;
    border-radius: 6px;
    font-weight: bold;
    max-width: 100%;
    &.delete_btn{
      border: 2px solid #E51F00;  
      color: #E51F00;
    }
    &.save_btn{
      border: 2px solid #3EC4FD;
      background-color: #3EC4FD;
      color: white;
    }
  }
}